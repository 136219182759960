import { Spa } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useStore } from "@priolo/jon";
import SelectorBase from "components/controls/base/SelectorBase";
import DatePicker from "components/controls/fields/DatePicker";
import NumberField from "components/controls/fields/NumberField";
import TextAreaField from "components/controls/fields/TextAreaField";
import Paragraph from "components/controls/form/Paragraph";
import Modal from "components/controls/Modal";
import dayjs from "dayjs";
import LostTrolley from "images/lostTrolley";
import { FunctionComponent } from "react";
import incidentSo from "stores/incidents";
import { CropLot } from "types/CropLot";
import { Cycle } from "types/Cycle";
import { INCIDENT_TYPE } from "types/Incident";
import LayersPositionSelector from "./LayersPositionSelector";




interface Props {
    cycle?: Cycle
}
const IncidentDialog: FunctionComponent<Props> = ({
    cycle
}) => {

    // STORE
    useStore(incidentSo)

    // HOOKs

    // HANDLEs
    const handleChangeProp = (prop: any) => incidentSo.setSelect({ ...incidentSo.state.select, ...prop })
    const handleChangeCropLot = (cropLot: CropLot) => {
        if (!cropLot) return
        incidentSo.state.select.lostTrolleysPerCropLot = [{ ...trolleysPerCropLot, cropLotId: cropLot.id }]
        incidentSo._update()
    }
    const handleChangeLost = (lostTrolleyNumber: number) => {
        incidentSo.state.select.lostTrolleysPerCropLot = [{ ...trolleysPerCropLot, numberOfLostTrolleys: lostTrolleyNumber }]
        incidentSo._update()
    }
    const handleClose = () => incidentSo.setEditDialogOpen(false)
    const handleSave = () => {
        incidentSo.save()
        incidentSo.setEditDialogOpen(false)
    }

    // RENDER
    const incident = incidentSo.state.select
    if (!incident || !cycle) return null
    const isNew = incident.id == null
    const trolleysPerCropLot = incidentSo.state.select?.lostTrolleysPerCropLot?.[0]
    const cropLotId = trolleysPerCropLot?.cropLotId
    const lostTrolleyNumber = trolleysPerCropLot?.numberOfLostTrolleys
    const canSave = !!cropLotId
    const flex = 30

    return <Modal
        icon={<LostTrolley />}
        title={isNew ? "CREATE INCIDENT" : "EDIT INCIDENT"}
        isOpen={incidentSo.state.editDialogOpen}
        onClose={handleClose}
        actionsRender={<>
            <div style={{ flex: "1 1 auto" }} />

            <Button
                onClick={handleClose}
            >CANCEL</Button>

            <Button
                onClick={handleSave}
                color="secondary"
                disabled={!canSave}
            >SAVE</Button>
        </>}
    >

        <Box sx={{ display: "flex", flexDirection: "column" }}>

            <Paragraph title={"DATE"} flex={flex}>
                <DatePicker
                    value={incident.dateOfOccurrence}
                    onChange={(value: dayjs.Dayjs) =>
                        handleChangeProp({ dateOfOccurrence: value.format("YYYY-MM-DD") })
                    }
                />
            </Paragraph>

            <Paragraph title={"INCIDENT TYPE"} flex={flex}>
                <SelectorBase sx={{ flex: 1 }}
                    title={"INCIDENT TYPE"}
                    icon={<Spa />}
                    items={Object.values(INCIDENT_TYPE)}
                    idSelect={incident.incidentType}
                    onChange={incidentType => handleChangeProp({ incidentType })}
                    fnTextFromItem={(type: string) => type.toUpperCase()}
                />
            </Paragraph>

            <Paragraph title={"CROP LOT"} flex={flex}>
                <SelectorBase sx={{ flex: 1 }}
                    title={"CROP LOTS"}
                    icon={<Spa />}
                    items={cycle.cropLots ?? []}
                    idSelect={cropLotId}
                    onChange={handleChangeCropLot}
                    fnTextFromItem={(cropLot: CropLot) => cropLot.cropName}
                    fnIdFromItem={(cropLot: CropLot) => cropLot.id}
                />
            </Paragraph>

            <Paragraph title={"TROLLEYS LOST"} flex={flex}>
                <NumberField sx={{ width: "146px" }}
                    min={1}
                    value={lostTrolleyNumber}
                    onChangeNumber={handleChangeLost}
                    disabled={!cropLotId}
                />
            </Paragraph>

            <Paragraph title={"LAYERS"} flex={flex}>
                <LayersPositionSelector sx={{ flex: 1 }}
                    cycle={cycle}
                    layersSelect={incident.positionOfTrolleys}
                    onChangeSelect={positionOfTrolleys => handleChangeProp({ positionOfTrolleys })}
                />
            </Paragraph>

            <Paragraph 
                title={"NOTES"} 
                flex={flex} 
                sxLabel={{ alignSelf: 'start', marginTop: '5px' }}
            >
                <TextAreaField sx={{ flex: 1 }}
                    title="NOTES"
                    multiline rows={6}
                    value={incident.notes}
                    onChange={e => handleChangeProp({ notes: e.target.value })}
                />
            </Paragraph>

        </Box>
    </Modal>
}

export default IncidentDialog