import { Layers } from "@mui/icons-material";
import { Button, List, ListItemButton, ListItemText } from "@mui/material";
import TextField from "components/controls/fields/TextField";
import Paragraph from "components/controls/form/Paragraph";
import Modal from "components/controls/Modal";
import CycleIcon from "images/cycle";
import { FunctionComponent, useEffect, useState } from "react";
import farmSo from "stores/farm";
import { getGrowUnitLayerName, getLayerByNumber } from "stores/growUnit/utils/layer";
import { Cycle } from "types/Cycle";
import { PositionTrolley } from "types/Incident";
import { Layer } from "types/Layer";



interface Props {
    positionTrolley: PositionTrolley
    ignoreLayerId?: number[]
    cycle: Cycle
    open: boolean
    onClose: (pt?: PositionTrolley) => void
}
const LayerPositionDialog: FunctionComponent<Props> = ({
    positionTrolley,
    ignoreLayerId = [],
    cycle,
    open,
    onClose,
}) => {

    // STORE

    // HOOKs
    const [numbers, setNumbers] = useState<string>("")
    const [position, setPosition] = useState<PositionTrolley>(null)
    useEffect(() => {
        if (!open) return
        let pos = positionTrolley ?? { layerId: null, layerNumber: null, positionOfTrolleys: [] }
        setPosition(pos)
        setNumbers(pos.positionOfTrolleys.join(" "))
    }, [open])

    // HANDLEs
    const handleLayerClick = (layer: Layer) => {
        setPosition({
            ...position,
            layerId: layer.id,
            layerNumber: layer.layerNumber,
        })
    }
    const handlePositionChange = (e: any) => {
        setNumbers(e.target.value)
    }
    const handleSave = () => {
        onClose({
            ...position,
            positionOfTrolleys: numbers.replace(/[^\d\s]/g, '').split(" ").map(n => parseInt(n)).filter(n => !isNaN(n))
        })
    }

    // RENDER
    const isSelect = (layer: Layer) => layer.id == position.layerId
    if (position == null) return null
    const canSave = !!position?.layerId
    const layers = cycle.layers.filter(l => !ignoreLayerId.includes(l.id))
    const flex = 70

    return <Modal sx={{ ".MuiDialog-paper": { width: "350px" } }}
        icon={<Layers />}
        title={"LAYER POSITION"}
        isOpen={open}
        onClose={() => onClose()}
        actionsRender={<>
            <div style={{ flex: 1 }} />
            <Button
                onClick={() => onClose()}
            >CANCEL</Button>
            <Button
                onClick={handleSave}
                color="secondary"
                disabled={!canSave}
            >SAVE</Button>
        </>}
    >

        <Paragraph title="LAYERS" flex={flex} sxLabel={{ alignSelf: 'start', mt: "15px" }}>
            <List sx={{ width: "100%" }}>
                {layers.map((layer, index) => (

                    <ListItemButton key={layer.id} dense
                        selected={isSelect(layer)}
                        onClick={() => handleLayerClick(layer)}
                    >
                        <ListItemText
                            primary={getNameLayer(cycle.growUnitId, layer.layerNumber)}
                        //secondary={fnSecondaryFromItem?.(layer)}
                        />
                    </ListItemButton>

                ))}
            </List>
        </Paragraph>

        <Paragraph title="POSITIONS" flex={flex} subtitle="divided by space">
            <TextField
                value={numbers}
                onChange={handlePositionChange}
            />
        </Paragraph>

    </Modal>
}

export default LayerPositionDialog

// i nomi dei layer utilizzati da questo CYCLE
const getNameLayer = (growUnitId: number, layerNumber: number) => {
    const growUnit = farmSo.getGrowUnit(growUnitId)
    return getGrowUnitLayerName(growUnit, getLayerByNumber(growUnit, layerNumber))
}