import ajax from "plugins/AjaxService"
import { METADATA_SUBJECT, NATSRequest } from "plugins/nats"
import farmSo from "stores/farm"
import { Metadata } from "types/Metadata"



/**
 * Recupera tutti i METADATA
 */
function index(baseUrl?: string): Promise<{ data: Metadata[] }> {
	if (farmSo.isInCloud()) {
		return NATSRequest(METADATA_SUBJECT)
	}
	return ajax.get(`metadata`, null, { baseUrl, isEdge: true })
}

/**
 * Rimuove un METADATA 
 */
function remove(meta: Metadata): Promise<void> {
	const baseUrl = farmSo.state.select?.edgeServicesConfig?.host?.url
	const data = {
		serviceCode: meta.serviceCode,
		name: meta.name,
	}
	return ajax.delete(`metadata`, data, { baseUrl, isEdge: true })
}

/**
 * Modifica/crea un METADATA
 */
function save(meta: Metadata): Promise<{ data: Metadata }> {
	const baseUrl = farmSo.state.select?.edgeServicesConfig?.host?.url
	return ajax.post(`metadata`, meta, { baseUrl, isEdge: true })
}

const api = {
	index,
	remove,
	save,
}

export default api