import { createStore, StoreCore } from "@priolo/jon";
import incidentsApi from "api/incidents";
import { Incident } from "types/Incident";



const setup = {

	state: {
		/** tutti gli INCIDENTS */
		all: <Incident[]>[],
		/** INCIDENT selezionato in questo momento */
		select: <Incident>null,

		lastFarmId: <number>null,

		editDialogOpen: <boolean>false,
	},

	getters: {

	},

	actions: {
		/** Carico tutti i gli INCIDENT di una specifica FARM */
		/** carico tutti i CROPS */
		fetchAll: async (farmId: number, store?: IncidentStore) => {
			const { data } = await incidentsApi.index(farmId)
			store.setAll(data)
		},
		fetchAllIfVoid: async (farmId: number, store?: IncidentStore) => {
			if (store.state.lastFarmId == farmId && store.state.all?.length > 0) return
			await store.fetchAll(farmId)
		},
		fetchSelect: async (id: number, store?: IncidentStore) => {
			const { data } = await incidentsApi.get(id)
			store.setSelect(data)
		},

		edit: (incident: Incident, store?: IncidentStore) => {
			if (!incident) return
			store.setSelect(incident)
			store.setEditDialogOpen(true)
		},
		save: async (_: void, store?: IncidentStore) => {
			const { data: incident } = await incidentsApi.save(store.state.select)
			if ( incident.cycleId == null ) incident.cycleId = store.state.select.cycleId
			const index = store.state.all.findIndex(i => i.id == incident.id)
			if (index == -1) store.state.all.push(incident)
			else store.state.all[index] = incident
			store.setEditDialogOpen(false)
			store.setSelect(null)
		},
		revert: async (incident: Incident, store?: IncidentStore) => {
			const { data } = await incidentsApi.revert(incident.id)
			incident.reverted = true
			const index = store.state.all.findIndex(i => i.id == incident.id)
			store.state.all[index] = incident
			store.setSelect(data)
		},
		proceeded: async (incident: Incident, store?: IncidentStore) => {
			const { data } = await incidentsApi.proceeded(incident.id)
			incident.reverted = false
			const index = store.state.all.findIndex(i => i.id == incident.id)
			store.state.all[index] = incident
			store.setSelect(data)
		},
		delete: async (incident: Incident, store?: IncidentStore) => {
			await incidentsApi.destroy(incident.id)
			store.setAll(store.state.all.filter(i => i.id != incident.id))
			store.setSelect(null)
		},
	},

	mutators: {
		setAll: (all: Incident[]) => ({ all }),
		setSelect: (select: Incident) => ({ select }),
		setEditDialogOpen: (editDialogOpen: boolean) => ({ editDialogOpen }),
	},
}


export type IncidentState = typeof setup.state
export type IncidentGetters = typeof setup.getters
export type IncidentActions = typeof setup.actions
export type IncidentMutators = typeof setup.mutators
export interface IncidentStore extends StoreCore<IncidentState>, IncidentGetters, IncidentActions, IncidentMutators {
	state: IncidentState
}
const incidentSo = createStore(setup) as IncidentStore
export default incidentSo