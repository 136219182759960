import humps from "humps"
import i18n from "i18next"
import { default as layoutSo, default as layoutStore } from "stores/layout"
import dialogSo, { DIALOG_TYPE } from "stores/layout/dialogStore"
import userStore from "stores/user"
import { Auth } from "stores/user/utils"



const isPLC = import.meta.env.VITE_TARGET == "plc"

interface OptionsParams {
	/** (true) request for login */
	isLogin?: boolean
	isEdge?: boolean
	/** (true) no show busy indictor */
	noBusy?: boolean
	/** (true) no show dialog errors */
	noDialogError?: boolean
	noCamelize?: boolean
	noDecamelize?: boolean
	/** { <status>: { <dialog settings> } } */
	errorOverride?: any,
	baseUrl?: string,
}

const optionsParamDefault: OptionsParams = {
	isLogin: false,
	isEdge: false,
	noBusy: false,
	noDialogError: false,
	noCamelize: false,
	noDecamelize: false,
	errorOverride: {},
	baseUrl: null,
}

export class AjaxService {
	private options: OptionsParams

	constructor() {
		this.options = {
			baseUrl: import.meta.env.VITE_API_MOCK == "true" || !import.meta.env.VITE_API_API_HOST
				? "/api/"
				: import.meta.env.VITE_API_API_HOST
		}
	}

	async post(url: string, data?: any, options?: OptionsParams) {
		return await this.send(url, "POST", data, options)
	}
	async get(url: string, data?: any, options?: OptionsParams) {
		return await this.send(url, "GET", data, options)
	}
	async patch(url: string, data?: any, options?: OptionsParams) {
		return await this.send(url, "PATCH", data, options)
	}
	async put(url: string, data?: any, options?: OptionsParams) {
		return await this.send(url, "PUT", data, options)
	}
	async delete(url: string, data?: any, options?: OptionsParams) {
		return await this.send(url, "DELETE", data, options)
	}

	/**
	 * Send a ajax to server
	 */
	async send(url: string, method: string, data: any, options: OptionsParams = {}) {
		const { setBusy, setFocus } = layoutStore
		const { logout } = userStore
		options = { ...optionsParamDefault, ...options }

		if (!options.noBusy) setBusy(true)

		// send request
		if (!options.noDecamelize) data = humps.decamelizeKeys(data)
		const headers = {
			"Content-Type": "application/json",
			"Accept": "application/json",
		}
		const auth = Auth.get()
		const authDisabled = options.isLogin || options.isEdge || isPLC
		if (auth && auth.length > 0 && !authDisabled) headers["Authorization"] = auth
		let response = null
		const baseUrl = options.baseUrl ?? this.options.baseUrl

		try {
			response = await fetch(
				`${baseUrl}${url}`,
				{
					method: method,
					headers,
					body: data ? JSON.stringify(data) : undefined,
					...(options.isLogin && !options.isEdge ? { credentials: 'include' } : { credentials: "omit" })
				}
			)

		} catch (error) {
			dialogSo.dialogOpen({
				type: DIALOG_TYPE.ERROR, title: "FETCH ERROR",
				text: error.message, labelOk: "ok",
			})
			throw error

		} finally {
			// setto l'interfaccia
			if (!options.noBusy) setBusy(false)
			setFocus("")
		}


		// prelevo i dati
		let ret = null
		let token = null
		try {
			token = response.headers.get("authorization")
			ret = await response.json()
			if (!options.noCamelize) ret = humps.camelizeKeys(ret)
		} catch (e) { }

		// MANAGE ERRORS
		const status = response.status
		if (status >= 400) {

			let error = ret?.error ? { code: ret.error, field: "" } : null
			error = !error && ret?.errors && ret.errors[0] ? ret.errors[0] : { code: "default", field: "" }

			if (status == 401) {
				await logout({ noHttp: true })
				if (authDisabled) {
					throw ret
					//error = { code: "login" }
				} else {
					dialogSo.dialogOpen({ type: DIALOG_TYPE.ERROR, modal: false, text: i18n.t("text-err.token-expired") })
					throw response
				}
			}

			// open dialog
			if (!options.noDialogError) {
				const msgDefault = {
					type: "error",
					title: i18n.t(getI18nPathForError(status, url, error.code, "title")),
					text: i18n.t(getI18nPathForError(status, url, error.code, "text")),
					labelOk: i18n.t(getI18nPathForError(status, url, error.code, "ok")),
				}
				const msg = { ...msgDefault, ...(options.errorOverride[`${status}`] ?? {}) }
				layoutSo.setLoadingMsg(null)
				await dialogSo.dialogOpen(msg)
			}

			// set focus
			if (error.field) setFocus(error.field)

			// throw error
			throw response
		}

		return options.isLogin ? token : ret
	}
}

/**
 * find correct text in i18n json 
 */
function getI18nPathForError(status: string, url: string, code: string, prop: string) {
	let i: number
	const context = url.slice(0, (i = url.indexOf("/")) != -1 ? i : undefined)
	return [
		`dialog.ajax.${context}.${code}.${prop}`,
		`dialog.ajax.${context}.${prop}`,
		`dialog.ajax.${status}.${code}.${prop}`,
		`dialog.ajax.${status}.${prop}`,
		`dialog.ajax.${prop}`
	]
}

export default new AjaxService()