import { useStore } from "@priolo/jon";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Close as CancelIcon, LibraryAdd as CloneIcon, ContentCopy as CopyIcon, ContentCut as CutIcon, Delete as DeleteIcon, RemoveDone as DeselectIcon, ContentPaste as PasteIcon, Check as SaveIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import ButtonCmp, { BTT_SHAPE } from "components/controls/buttons/ButtonCmp";
import NumberField from "components/controls/fields/NumberField";
import recipeSo from "stores/library/recipe";
import daysSo from "stores/library/recipe/detail";
import { getUrlByPageId } from "stores/route/utils/pagesId";
import { PAGE_ID } from "stores/route/utils/types";
import { Day } from "types/Recipe";
import Modal from "components/controls/Modal";
import theme from "styles/theme";



const RecipeDetailFooter: FunctionComponent = () => {

	// STORE
	const daysSa = useStore(daysSo)

	// HOOKs
	const history = useHistory()
	const { t } = useTranslation()
	const [pasteNum, setPasteNum] = useState(1)
	const [cloneOpen, setCloneOpen] = useState(false)


	// HANDLERs
	const handleClickSaveRecipe = async () => {
		const saved = await recipeSo.saveSelect()
		if (saved) history.push(getUrlByPageId(PAGE_ID.RECIPES))
	}
	const handleDeselect = () => {
		daysSo.clearSelection()
	}
	const handleCopy = () => {
		daysSo.setClipboard(daysSo.getAllSelect())
		daysSo.clearSelection()
	}
	const handleCut = () => {
		daysSo.setClipboard(daysSo.getAllSelect())
		handleDelete()
	}
	const handleDelete = () => {
		for (const g of daysSa.daysGroups) {
			if (g.daysSelect?.length == 0) continue
			g.days = g.days.filter((d, i) => !g.daysSelect.includes(i))
		}
		daysSo.clearSelection()
	}
	const handlePaste = () => {
		const toPaste = daysSa.clipboard
		for (const g of daysSa.daysGroups) {
			if (g.daysSelect?.length == 0) continue
			const indexSel = g.daysSelect[0] + 1
			g.days.splice(indexSel, 0, ...toPaste)
			g.daysSelect = Array.from({ length: toPaste.length }, (x, i) => i + indexSel)
		}
		//daysSo.setClipboard([])
		daysSo.clearSelection()
	}

	const handleClone = () => setCloneOpen(true)
	const handleCloneClose = () => setCloneOpen(false)
	const handleCloneApply = () => {
		setCloneOpen(false)
		// creo i cloni
		const toClone = Array<Day[]>(pasteNum).fill(daysSo.getAllSelect()).flat()

		// ciclo tutti i gruppi
		for (const g of daysSa.daysGroups) {
			if (g.daysSelect?.length == 0) continue
			const indexSel = Math.max(...g.daysSelect) + 1
			g.days.splice(indexSel, 0, ...toClone)
			g.daysSelect = Array.from({ length: toClone.length }, (x, i) => i + indexSel)
		}
		daysSo.setDaysGroups([...daysSa.daysGroups])
		setPasteNum(1)
	}
	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.code == "Enter") handleClone()
	}

	// RENDER
	const haveSelect = daysSa.daysGroups.some(g => g.daysSelect.length > 0)
	const haveClipboard = daysSa.clipboard?.length > 0
	const EditButtons = <>
		<Box sx={{ borderLeft: `1px solid ${theme.palette.grey.dark}` }} />

		<ButtonCmp shape={BTT_SHAPE.OPTION}
			onClick={handleDeselect}
			startIcon={<DeselectIcon />}
		>Deselect All</ButtonCmp>

		<ButtonCmp shape={BTT_SHAPE.OPTION}
			onClick={handleCopy}
			startIcon={<CopyIcon />}
		>Copy</ButtonCmp>

		<ButtonCmp shape={BTT_SHAPE.OPTION}
			onClick={handleCut}
			startIcon={<CutIcon />}
		>Cut</ButtonCmp>

		{haveClipboard && <>
			<ButtonCmp shape={BTT_SHAPE.OPTION}
				onClick={handlePaste}
				startIcon={<PasteIcon />}
			>Paste</ButtonCmp>
		</>}

		<ButtonCmp shape={BTT_SHAPE.OPTION}
			onClick={handleClone}
			startIcon={<CloneIcon />}
		>Clone</ButtonCmp>
		<Modal maxWidth="xs" icon={<CloneIcon />} title="CLONE THE SELECTED RECIPES"
			isOpen={cloneOpen}
			onClose={handleCloneClose}
			actionsRender={<><Box sx={{ flex: 1 }} />
				<Button onClick={handleCloneClose}>Cancel</Button>
				<Button onClick={handleCloneApply} color="secondary">Clone</Button>
			</>}
		>
			<Box sx={{ display: "flex", alignItems: "center" }} >
				<Typography>Number of clones to create:</Typography>
				<NumberField sx={{ width: "50px", ml: 2 }}
					value={pasteNum}
					onChangeNumber={value => setPasteNum(value)}
					onKeyDown={handleKeyDown}
				/>
			</Box>
		</Modal>

		<ButtonCmp shape={BTT_SHAPE.OPTION_DELETE}
			onClick={handleDelete}
			startIcon={<DeleteIcon />}
		>Delete</ButtonCmp>
	</>

	return (
		<Box sx={{ ml: "26px", display: "flex", gap: 2 }}>
			<ButtonCmp
				shape={BTT_SHAPE.MAIN}
				startIcon={<SaveIcon />}
				onClick={handleClickSaveRecipe}
			>
				{t("footer.recipes.save")}
			</ButtonCmp>

			<ButtonCmp
				href="/recipes"
				startIcon={<CancelIcon />}
			>
				{t("footer.recipes.cancel")}
			</ButtonCmp>

			{haveSelect && EditButtons}
		</Box>
	)
}

export default RecipeDetailFooter


