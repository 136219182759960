import { Box, Switch, Typography } from "@mui/material";
import { useStore } from "@priolo/jon";
import DatePicker from "components/controls/fields/DatePicker";
import NumberField from "components/controls/fields/NumberField";
import TextAreaField from "components/controls/fields/TextAreaField";
import TextField from "components/controls/fields/TextField";
import TimeField from "components/controls/fields/TimeField";
import Paragraph from 'components/controls/form/Paragraph';
import SelectorScope from "components/controls/select/SelectorScope";
import dayjs from 'dayjs';
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import detailSo from "stores/task/taskDetail";
import { dateToHuman, timeToHuman } from "utils";
import { getRef } from "utils/humanize";



interface Props {
	readOnly?: boolean
	sx?: any
}
const TaskDetailTab: FunctionComponent<Props> = ({
	readOnly,
	sx,
}) => {

	// STORE
	const detailSa = useStore(detailSo)

	// HOOKs
	const { t } = useTranslation()

	// HANDLERs
	const handleChangeField = (value) => {
		if (readOnly) return
		detailSo.setSelect({ ...detailSa.select, ...value })
	}

	// RENDER
	if (!detailSa.select) return null
	const closure = `${dateToHuman(detailSa.select.firstClosedAt)} ${timeToHuman(dayjs(detailSa.select.firstClosedAt))}`
	const isClosed = detailSa.select.isClosed
	const relatedTo = detailSa.select.relatedTo?.replace("_", " ").toUpperCase() ?? "--"
	const ref = getRef(detailSa.select?.taskUuid) ?? "--"

	return (
		<Box sx={[{ display: "flex", flexDirection: "column" }, sx]}>

			<Paragraph flex={30} title={t("dialog.task.details.ref")}>
				<Typography variant="subtitle1" sx={{ ml: "14px" }}>{ref}</Typography>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.subject")}>
				<TextField sx={{ flex: 1 }}
					readOnly={readOnly}
					value={detailSa.select.subject ?? ""}
					onChange={e => handleChangeField({ subject: e.target.value })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.description")} sx={{ alignItems: "baseline" }}>
				<TextAreaField sx={{ flex: 1 }}
					title={t("dialog.task.details.description")}
					readOnly={readOnly}
					multiline rows={6}
					value={detailSa.select.description ?? ""}
					onChange={e => handleChangeField({ description: e.target.value })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.scope")}>
				<SelectorScope sx={{ flex: 1 }}
					readOnly={readOnly}
					scope={detailSa.select.scope}
					onChange={scope => handleChangeField({ scope })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.points")}>
				<NumberField sx={{ width: "100px" }}
					readOnly={readOnly}
					value={detailSa.select.points ?? ""}
					onChangeNumber={value => handleChangeField({ points: +value, totalPoints: (+value + detailSa.select.trolleysPoints) })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.points-trolley")}>
				<NumberField sx={{ width: "100px" }}
					readOnly={readOnly}
					value={detailSa.select.trolleysPoints ?? ""}
					onChangeNumber={value => handleChangeField({ trolleysPoints: +value, totalPoints: (+value + detailSa.select.points) })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.date-time")}>
				<DatePicker
					readOnly={readOnly}
					value={dayjs(detailSa.select.dueDate)}
					onChange={value => handleChangeField({ dueDate: dayjs(value).format("YYYY-MM-DD") })}
				/>
				<TimeField sx={{ marginLeft: "5px", width: "100px" }}
					value={detailSa.select.dueTime ?? ""}
					onChange={(dueTime: string) => handleChangeField({ dueTime: dueTime?.length == 0 ? null : dueTime })}
					readOnly={readOnly}
				/>
			</Paragraph>

			{isClosed &&
				<Paragraph flex={30} title={t("dialog.task.details.closure")}>
					<Typography variant="subtitle1" sx={{ ml: "14px" }}>{closure}</Typography>
				</Paragraph>
			}

			<Paragraph flex={30} title={t("dialog.task.details.updates-yield")}>
				<Switch
					checked={detailSa.select.updatesYield}
					onChange={e => handleChangeField({ updatesYield: e.target.checked })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.cancel-if-cycle-rejected")}>
				<Switch
					checked={detailSa.select.cancelIfCycleRejected}
					onChange={e => handleChangeField({ cancelIfCycleRejected: e.target.checked })}
				/>
			</Paragraph>

			<Paragraph flex={30} title={t("dialog.task.details.related-to")}>
				<Typography variant="subtitle1" sx={{ ml: "14px" }}>{relatedTo}</Typography>
			</Paragraph>

		</Box>
	)
}

export default TaskDetailTab
