import { Today as TodayIcon, Visibility, Warning as WarningIcon } from '@mui/icons-material';
import { Box, SxProps, TableCell, TableRow, Tooltip } from '@mui/material';
import { useStore } from '@priolo/jon';
import { TAGS } from 'components/planner/types';
import React, { FunctionComponent, useMemo } from 'react';
import cycleSo from 'stores/cycle';
import cycleDialogSo, { CYCLE_EDIT_TYPE } from 'stores/cycle/editDialog';
import { getCropNames, getCycleStatus, getCycleStatusLabel, getLayersInHarvest, getPlannedYield, getYield } from 'stores/cycle/utils/cycle';
import { EditCycle } from 'stores/cycle/utils/edit';
import { SESSION_TYPE } from 'stores/draft/utils';
import querySo from "stores/route/query";
import { URL_PAR } from 'stores/route/utils/types';
import { Cycle } from 'types/Cycle';
import { isToday } from 'utils';
import { getSxRow } from 'utils/entity';
import { dateFormat, getRef } from 'utils/humanize';
import guSo from "stores/growUnit"
import { getGrowUnitName } from 'stores/growUnit/utils';
import theme from 'styles/theme';
import { CYCLE_STATUS } from 'stores/cycle/utils/enums';
import farmSo from 'stores/farm';



interface Props {
    cycle: Cycle
}

const CyclesRow: FunctionComponent<Props> = ({
    cycle,
}) => {

    // STORE
    const cycleSa = useStore(cycleSo)
    const querySa = useStore(querySo)

    // HOOKs
    const cycleUuidSel = useMemo(() => querySo.getSearchUrl(URL_PAR.CYCLE_SEL), [querySa.queryUrl])

    // HANDELEs
    // click editazione di un CYCLEs
    const handleDClickRow = async (e: React.MouseEvent, cycle: Cycle) => {
        e.stopPropagation()
        const action = await cycleDialogSo.open(cycle)
        if (action == CYCLE_EDIT_TYPE.REJECT) {
            querySo.updateQueryUrl()
        } else if (action == CYCLE_EDIT_TYPE.HARVEST) {
            await cycleSo.fetch({ force: true })
            querySo.updateQueryUrl()
        }
    }
    // seleziono la riga
    const handleClickRow = async (e: React.MouseEvent, cycle: Cycle) => {
        e.stopPropagation()
        querySo.setSearchUrl([URL_PAR.CYCLE_SEL, cycle.cycleUuid])
    }

    // RENDER
    const isSelect = (cycle: Cycle): boolean => cycle.cycleUuid == cycleUuidSel
    const sxRow = useMemo(() => getSxRow(cycle._edit?.type), [cycle._edit?.type])
    const guName = useMemo(() => getGrowUnitName(farmSo.getGrowUnit(cycle.growUnitId)), [cycle, farmSo.state.select])
    const isPreview = EditCycle.getExtra(cycle, TAGS.SESSION) == SESSION_TYPE.PREVIEW
    const unitYield = isNaN(cycle.unitsYield) || cycle.unitsYield == null ? "--" : cycle.unitsYield
    const cycleStatus = getCycleStatus(cycle)

    return <TableRow hover
        sx={{ cursor: "pointer" }}
        onDoubleClick={e => handleDClickRow(e, cycle)}
        onClick={e => handleClickRow(e, cycle)}
        selected={isSelect(cycle)}
    >
        <TableCell scope="cycle" sx={sxRow}>
            <Box sx={{ display: "flex" }}>
                <RowRef cycle={cycle} />
                {isPreview && <Visibility sx={{ ml: 1 }} />}
            </Box>
        </TableCell>
        <TableCell sx={sxRow}>
            {guName}
        </TableCell>
        <TableCell sx={sxRow}>
            {getCropNames(cycle)}
        </TableCell>
        <TableCell align="center" sx={[{}, sxRow]}>
            <Box sx={sxStatus(cycleStatus)}>{getCycleStatusLabel(cycleStatus)}</Box>
        </TableCell>
        <TableCell sx={sxRow}>
            <RowDateToday date={cycle.seedingDate} />
        </TableCell>
        <TableCell sx={sxRow}>
            <RowDateToday date={cycle.harvestingDate} />
        </TableCell>
        <TableCell align="center" sx={sxRow}>
            {getLayersInHarvest(cycle)}
        </TableCell>
        <TableCell align="right" sx={sxRow}>
            {getPlannedYield(cycle)}
        </TableCell>
        <TableCell align="right" sx={sxRow}>
            {getYield(cycle)}
        </TableCell>
        <TableCell align="right" sx={sxRow}>
            {unitYield}
        </TableCell>
    </TableRow>
}

export default CyclesRow

const sxStatus = (cycleStatus: CYCLE_STATUS): SxProps => {
    const color = {
        [CYCLE_STATUS.NEW]: theme.palette.primary,
        [CYCLE_STATUS.PLANNED]: theme.palette.primary,
        [CYCLE_STATUS.RUNNING]: theme.palette.success,
        [CYCLE_STATUS.HARVESTED]: theme.palette.secondary,
        [CYCLE_STATUS.REJECTED]: theme.palette.error,
    }[cycleStatus]
    return {
        fontSize: "12px",
        fontWeight: 700,
        bgcolor: color.main,
        color: color.contrastText,
        textTransform: "uppercase",
        borderRadius: "5px",
        padding: "2px 2px",
    }
}




const RowRef = ({ cycle }: { cycle: Cycle }) => {
    const haveWarn = (cycle: Cycle): boolean =>
        cycle.layers?.some(layer => layer["errors"]?.length > 0) ?? false
    return <Box sx={{ display: "flex" }}>
        {getRef(cycle?.cycleUuid)}
        {haveWarn(cycle) &&
            <WarningIcon color="secondary" fontSize="small" sx={cssIcon} />
        }
    </Box>
}

const RowDateToday = ({ date }: { date: string }) => <Box sx={{ display: "flex" }}>
    {dateFormat(date)}
    {isToday(date) && <Tooltip title="TODAY">
        <TodayIcon fontSize="small" sx={cssIcon} />
    </Tooltip>}
</Box>
const cssIcon = {
    marginLeft: "5px",
    marginRight: "-20px"
}
