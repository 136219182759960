import { Badge, Tooltip } from "@mui/material";
import { CalendarPicker, LocalizationProvider, PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { FunctionComponent, useEffect, useRef } from "react";
import querySo from "stores/route/query";
import { URL_PAR } from "stores/route/utils/types";
import theme from "styles/theme";
import { dateRangeToUrl, daysDuration, urlToDateRange } from "utils";



export interface RenderDayProps {
	tooltip?: string
	isModify?: boolean
	color?: string
	bgcolor?: string

}

interface Props {
	renderDay?: (day: Dayjs) => RenderDayProps
	sx?: any
}

const RangeUrlCalendar: FunctionComponent<Props> = ({
	renderDay,
	sx
}) => {

	// STORE

	// HOOKs
	const shift = useRef(false)
	useEffect(() => {
		const handlekeyDown = (e: KeyboardEvent) => shift.current = e.shiftKey
		const handlekeyUp = (e: KeyboardEvent) => shift.current = false
		document.addEventListener('keydown', handlekeyDown)
		document.addEventListener('keyup', handlekeyUp)
		return () => {
			document.removeEventListener("keydown", handlekeyDown)
			document.removeEventListener("keyup", handlekeyUp)
		}
	}, [])

	// HANDLE
	const handleDateChange = (date: Dayjs) => {
		const dateNum = date.valueOf()
		let range = { ...dateRange }
		if (shift.current) {
			range.end = dateNum
		} else {
			range = { start: dateNum, end: dateNum }
		}
		querySo.setSearchUrl([URL_PAR.DATE_SEL, dateRangeToUrl(range)])
		//querySo.updateQueryUrl()
	}

	// RENDER
	const dateRangeUrl = querySo.getSearchUrl(URL_PAR.DATE_SEL)
	const dateRange = urlToDateRange(dateRangeUrl)
	const singleDay = daysDuration(dateRange) == 0
	const dateSel = dayjs(dateRange.start)//singleDay ? dayjs(dateRange.start) : null

	const renderDayLocal = (day: Dayjs, selectedDay: any, pickersDayProps: PickersDayProps<Dayjs>) => {
		const { tooltip, isModify, color, bgcolor } = renderDay?.(day) ?? {}
		const css = [{ bgcolor }, cnDay(day), { color }]
		return (
			<Tooltip title={tooltip} key={pickersDayProps.key}>
				<Badge color="secondary" variant="dot" overlap="circular" invisible={!isModify} sx={cssBadge}>
					<PickersDay {...pickersDayProps} disableMargin sx={css} />
				</Badge>
			</Tooltip>
		)
	}
	const cnDay = (day: Dayjs) => {
		let cssMultiselect = null
		if (!singleDay && day.isBetween(dateRange.start, dateRange.end, "day", "[]")) {
			cssMultiselect = {
				borderRadius: 0,
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
				'&:hover, &:focus': {
					backgroundColor: theme.palette.secondary.main,
				},
			}
			if (day.isSame(dateRange.start, "day")) {
				cssMultiselect = {
					...cssMultiselect,
					borderTopLeftRadius: '50%',
					borderBottomLeftRadius: '50%',
				}
			} else if (day.isSame(dateRange.end, "day")) {
				cssMultiselect = {
					...cssMultiselect,
					borderTopRightRadius: '50%',
					borderBottomRightRadius: '50%',
				}
			}
		} else {
			cssMultiselect = {}
		}
		return {
			width: "40px",
			fontWeight: 900,
			...cssMultiselect,
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<div onClick={(e) => e.stopPropagation()}>
				<CalendarPicker
					date={dateSel}
					onChange={handleDateChange}
					renderDay={renderDayLocal}
				/>
			</div>
		</LocalizationProvider>
	)
}

export default RangeUrlCalendar

const cssBadge = {
	width: "40px",
	'& .MuiBadge-badge': {
		right: 6,
		border: `2px solid ${theme.palette.background.header}`,
	},
}